import React from "react";
import styled, {css} from "styled-components";
import {RProp} from "../../common/interfaces";
import {MediumDeviceWidth, Theme} from "../../common/constants";
import useWindowSize from "../../hooks/useWindowSize";

const FooterBox = styled.div<{theme: Theme, showLongMargin: boolean}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap:10px;
  color: ${p=> p.theme === Theme.day? '#656161': '#f1eeee'};
  font-family: "Microsoft YaHei", sans-serif;
  font-size: 12px;
  margin-bottom: ${p=> p.showLongMargin ? '150px': '20px'};
`
export const Line = styled.div<{theme: Theme, lindWidth: number}>`
  width: ${p=>p.lindWidth}px;
  height: 1px;
  background-color: ${p=> p.theme === Theme.day? '#c9c2c2': '#c0b7b7'};
`

interface FooterProps extends RProp{
    theme: Theme
}

export const FooterContainer: React.FC<FooterProps> = ({children, theme})=>{
    const {width} = useWindowSize()
    const showLongMargin = width< MediumDeviceWidth
    return (
        <FooterBox theme={theme} showLongMargin={showLongMargin}>
            {children}
        </FooterBox>
    )
}