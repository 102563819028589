import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import mainFilterSlice from "./reducer/mainFilter";
export const store =  configureStore({
    reducer: {
        mainFilter: mainFilterSlice,
    }
})
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;