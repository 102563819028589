import React, {useState} from "react";
import {TagApiData, TagProps} from "../../common/interfaces";
import Tag from "./tag";
import {TagListContainer} from "./css";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setTagIdList} from "../../store/reducer/mainFilter";

interface SimpleTagListProps{
    tagList: TagApiData[]
    region: 'onCard' | 'onContent'
}

export default function SimpleTagList(p: SimpleTagListProps){
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const onClick = (id: number)=>{
        if(p.region === 'onContent'){
            const prefix = location.pathname.includes('video')? 'video': 'article'
            dispatch(setTagIdList(id.toString()))
            navigate(`/search/${prefix}`)
        }
    }
    return(
        <TagListContainer width={750}>
            {
                p.tagList.map((tag) => {
                    return <Tag region={p.region}  id={tag.id} name={tag.name} active={false} onClick={onClick}/>
                })
            }
        </TagListContainer>
    )
}