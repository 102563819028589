import styled from "styled-components";
import {Theme} from "../../common/constants";

export const ImgModalOverlay = styled.div<{align: string}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: ${p=>p.align};
  z-index: 10;
`
export const ImgModal = styled.div<{theme: Theme, width: string, height: string}>`
  position: relative;
  border-radius: 10px;
  width: ${p=>p.width};
  height: ${p=>p.height};
  overflow: hidden;
   background-color: ${p=> p.theme===Theme.day? 'rgba(0, 0, 0, 0.7)': 'rgba(255,255,255,0.7)'};
  display: flex;
  flex-direction: column;
`
export const Image = styled.img.attrs(props => ({
  src: props.src,
}))`
  width: ${p=>p.width}px;
  height: auto;
  border-radius: 10px;
  object-fit: contain;
  flex-shrink: 0;
  max-width: none;
`;
export const ScrollableContent = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  flex: 1; /* 使这个 div 填充剩余空间 */
  overflow: auto; /* 使这个区域可滚动 */
  box-sizing: border-box; /* 确保 padding 不会影响布局 */
`;
export const OperationContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 45px;
`
export const IconBackground = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
  gap: 10px;
  background-color: rgba(63, 59, 59, 0.3);
  border-radius: 10px;
  color: white;
`

export const CloseContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(63, 59, 59, 0.5);
  padding: 5px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  
`