import styled from "styled-components";
import {Theme} from "../../common/constants";

const getColor = (theme: Theme, active: boolean)=>{
    if(theme === Theme.day){
        return active? 'white': '#0f0f0f'
    }else{
        return active? 'white': 'white'
    }
}

const getBackground = (theme: Theme, active: boolean)=>{
    if(theme === Theme.day){
        return active? 'black': '#fcfbfb'
    }else{
        return active? '#fcfbfb': 'black'
    }
}

export const TagContainer = styled.div<{region?: 'onCard' | 'onContent', theme: Theme, active: boolean}>`
  display: flex;
  border-radius: ${p=> p.region === 'onCard'? 7: 10}px;
  width: fit-content;
  padding: ${p=> p.region === 'onCard'? '3px 5px': '6px 9px'};
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: "Microsoft YaHei", sans-serif;
  font-size: ${p=> p.region === 'onCard'? 14: 17}px;
  color: ${p=>getColor(p.theme,p.active)};
  background: ${p=> getBackground(p.theme, p.active)};
  ${p => p.active? 'background-image: linear-gradient(315deg, #fc9842 0%, #ff2424 74%);': ''};
  border: 1px solid ${p=> p.theme === Theme.day? '#eee1e1': 'black'};
  
  &:hover{
    cursor: pointer;
  }
`
export const TagListContainer = styled.div<{width: number}>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  max-width: ${p=> p.width}px;
  flex-wrap: nowrap;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Disable vertical scrolling */
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`

export const StickyTagWrapper = styled.div`
  position: sticky;
  left: 0;
  flex-shrink: 0;
`;

export const TagOuterContainer = styled.div<{theme: Theme}>`
    display: flex;
    position: fixed;
    top: 60px;
    z-index: 5;
    padding: 5px 5px;
    border-radius: 10px;
    background-color: ${p=> p.theme === Theme.night? 'rgba(0, 0, 0, 0.5)': 'rgba(234,224,224,0.5)'};
`

export const RedArrowContainer = styled.div<{theme: Theme, pos: string}>`
  display: flex;
  position: absolute;
  top: 12px;
  width: 25px;
  height: 25px;
  //background: white;
  ${p=> p.pos==='left'? 'left: -25px': 'right: -30px'};
  &:hover{
    cursor: pointer;
    border-radius: 50%;
    background: ${p=> p.theme === Theme.day? '#e3dada': '#0e0e0e'};
  }
`