import {postData} from "./config";
import { v4 as uuidv4 } from 'uuid';

function getQueryParams(): Record<string, string> {
    const queryParams: Record<string, string> = {};
    const queryString = window.location.search.substring(1); // 去掉开头的问号

    // 将参数字符串分割成多个键值对
    const pairs = queryString.split('&');
    for (const pair of pairs) {
        const [key, value] = pair.split('=');
        queryParams[decodeURIComponent(key)] = decodeURIComponent(value || '');
    }

    return queryParams;
}
function generateShortUUID() {
    return uuidv4().toUpperCase()
}

export enum LogEnum{
    VideoCardMC = 'videoCard_mc',
    ArticleCardMC = 'articleCard_mc',
    TagMC = 'tag_mc',
    VideoButtonMC = 'videoButton_mc',
    ArticleButtonMC = 'articleButton_mc',
    ThemeButtonMC = 'themeButton_mc',
    SearchButtonMC = 'searchButton_mc',
    RecommendVideoCardMC = 'recommendVideoCard_mc',
    RecommendArticleCardMC = 'recommendArticleCard_mc',

    HomeMV = 'home_mv',
    SearchMV = 'search_mv',
    ArticleMV = 'article_mv',
    VideoMV = 'video_mv',

    AdBlocker = 'adBlocker'
}

export function saveLog(prefix: LogEnum, id: string){
    const operation = `${prefix}_${id}`
    let deviceId = localStorage.getItem('deviceId')
    if(!deviceId){
        deviceId = generateShortUUID();
        localStorage.setItem('deviceId', deviceId)
    }
    const ua = navigator.userAgent.toString()
    const params = getQueryParams()
    postData<boolean>('/log/saveLog', {userAgent: ua, source: params['source'] || '', operation, deviceId}).then(()=>{
    }).catch((e)=>{
        console.log('saveLog error')
    })
}