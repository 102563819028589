import React from "react";
import {
  ArticleColor,
  IconColor,
  MainColor,
  MediumDeviceWidth,
  SmallDeviceWidth,
  Theme,
  VideoColor
} from "../../common/constants";
import styled, {css} from "styled-components";
import {ReactComponent as novaTechSVG} from '../../assets/novaTech.svg'
import {ReactComponent as searchSVG} from '../../assets/search.svg'
import {ReactComponent as clearSVG} from '../../assets/clear.svg'
import {ReactComponent as videoSVG} from '../../assets/video.svg'
import {ReactComponent as articleSVG} from '../../assets/article.svg'
import {ReactComponent as daySVG} from '../../assets/day.svg'
import {ReactComponent as nightSVG} from '../../assets/night.svg'
import {iconStyle} from "../../common/styles";



const baseHeaderStyle = css`
  position: fixed;
  z-index: 2;
  display: grid;
  grid-template-columns: 130px auto repeat(2, 90px) 20px 5px;
  grid-auto-rows: 1fr;
  align-items: center;
  grid-template-areas: "logo search article theme padding";
  height: 50px;
  width: 100vw;
  grid-column-gap: 15px;
`

const headerNightTheme = css`
  background-color: #17202a;
`
const headerDayTheme = css`
  background-color: #ffffff;
`

export const HeaderContainer = styled.div<{ theme: Theme, width: number }>`
  ${baseHeaderStyle};
  ${props => props.width< MediumDeviceWidth ? `
    grid-template-columns: 50px auto  ${50* props.width/MediumDeviceWidth}px 5px;
    grid-template-areas: "logo search theme padding";
  ` : ''};
  
  ${({theme}) => theme === Theme.night ? headerNightTheme : headerDayTheme}
  border-bottom: ${p=> p.theme === Theme.day? '#e7e1e1': ''} 1px solid;
`;


export const LogoIcon = styled(novaTechSVG)`
  ${iconStyle({size: 20, color: 'white'})}
`

export const SearchIcon = styled(searchSVG)<{theme: Theme}>`
  ${p=> iconStyle({size: 20, color: p.theme === Theme.night? 'white': 'black'})};
  position: absolute;
  left: 10px;
  z-index: 2;
`;

export const ClearIcon = styled(clearSVG)<{theme: Theme}>`
  ${p=> iconStyle({size: 20, color: p.theme === Theme.night? 'white': 'black'})};
  position: absolute;
  right: 10px;
  z-index: 2;
`;

export const VideoIcon = styled(videoSVG)<{size: number}>`
  ${p=> iconStyle({size: p.size, color: 'white'})}
`

export const ArticleIcon = styled(articleSVG)<{size: number}>`
  ${p=> iconStyle({size: p.size, color: 'white'})};
`

export const DayIcon = styled(daySVG)`
  ${iconStyle({size: 20, color: 'white'})}
`

export const NightIcon = styled(nightSVG)`
  ${iconStyle({size: 20, color: 'white'})}
`

export const LogoName = styled.div<{ theme: Theme }>`
  font-weight: bold;
  font-family: "Microsoft YaHei", sans-serif;
  ${({theme}) => theme === Theme.night ? 'color: white;' : 'color: black;'}
`

export const LogoContainer = styled.div`
    grid-area: logo;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    &:hover {
      cursor: pointer;
    }
`

export const Input = styled.input<{ theme: Theme}>`
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  height: 100%;
  border: 0;
  outline: none;
  border-radius: 24px;
  font-size: 16px;
  background: ${p => p.theme === Theme.day ? '#f1ebeb' : '#676262'};
  caret-color: ${p => p.theme === Theme.night ? '#f1ebeb' : '#b2acac'};
  color: ${p=> p.theme === Theme.night ? '#f1ebeb' : '#3f3a3a'};
  
  &:focus {
    border: 1px solid ${MainColor};
    animation: bounceIn;
    animation-duration: 0.35s;
  }
`

export const InputContainer = styled.div<{ theme: Theme, width: number}>`
  grid-area: search;
  position: relative;
  display: flex;
  width: ${p=> p.width}px;
  height: 35px;
  justify-content: center;
  align-items: center;
`

export const InputPosition = styled.div<{width: number}>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`
export const nameCss = (theme: Theme)=>{
  return css`
    font-family: "Microsoft YaHei", sans-serif;
    color: white;
  `
}
const textContainer = (area: string, color1: string, color2: string)=>{
  const gradient1 = `linear-gradient(315deg, ${color1} 0%, ${color2} 74%)`
  const gradient2 = `linear-gradient(315deg, ${color2} 10%, ${color1} 80%)`
  return css`
    grid-area: ${area};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 24px;
    height: 25px;
    padding: 5px 8px 5px 8px;
    background-image: ${gradient1};
    transition: background-image 0.3s ease;
    
    &:hover {
      cursor: pointer;
      background-image: ${gradient2};
      animation: pulse;
      animation-duration: 0.2s;
    }
  `
}

export const VideoContainer = styled.div`
  ${textContainer('video', VideoColor.buttonGradient.color1, VideoColor.buttonGradient.color2)}
`

export const VideoName = styled.div<{theme: Theme}>`
${p=> nameCss(p.theme)};
`
export const ArticleContainer = styled.div<{theme: Theme}>`
  ${textContainer('article', ArticleColor.buttonGradient.color1, ArticleColor.buttonGradient.color2)}
`
export const ArticleName = styled.div<{ theme: Theme }>`
  ${p=> nameCss(p.theme)};
`

export const IconContainer = styled.div<{size: number}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-image: linear-gradient(315deg, ${IconColor.a} 0%, ${IconColor.b} 74%);
  width: ${p=> p.size}px;
  height: ${p=> p.size}px;
  padding: 3px;
`
