import React from "react";
import styled, {css} from "styled-components";
import {ReactComponent as DateSVG} from '../../../assets/date.svg'
import {ReactComponent as ReadSVG} from '../../../assets/read.svg'
import {IconProps, RProp} from "../../../common/interfaces";
import {iconStyle} from "../../../common/styles";
import {SmallDeviceWidth, Theme} from "../../../common/constants";
import useWindowSize from "../../../hooks/useWindowSize";

const TitleName = styled.div<{theme: Theme, fontSize: number}>`
  font-family: "Microsoft YaHei", sans-serif;
  padding-left: 10px;
  padding-right: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* 设置显示的行数 */
  white-space: normal;
  font-size: ${p=>p.fontSize}px;
  color: ${p=> p.theme=== Theme.day? 'black': 'white' };
`

export const Title = styled.div<{isSmallDevice: boolean}>`
  display: flex;
  align-items: center;
  height: ${p=>p.isSmallDevice? 45: 60}px;
`
interface TitleProps extends RProp{
    theme: Theme
}

export const TitleContainer: React.FC<TitleProps> = ({theme, children})=>{
    const {width} = useWindowSize()
    const fontSize = width< SmallDeviceWidth? 15: 17;
    return (
        <Title isSmallDevice={width< SmallDeviceWidth}>
            <TitleName theme={theme} fontSize={fontSize}>{children}</TitleName>
        </Title>
    )
}
export const DateIcon = styled(DateSVG)<{theme: Theme, fontSize: number}>`
  ${p=> iconStyle({size: p.fontSize, color:  p.theme === Theme.night? 'grey': 'grey'})}
`
export const SmallText = styled.div<{theme: Theme, fontSize: number}>`
  font-family: "Microsoft YaHei", sans-serif;
  font-size: ${p=>p.fontSize}px;
  color: ${p=> p.theme=== Theme.day? 'grey': 'grey' };;
`
export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`

export const ReadIcon = styled(ReadSVG)<{theme: Theme}>`
  ${p=> iconStyle({size: 16, color:  p.theme === Theme.night? 'grey': 'grey'})}
`
export const TextContainer= styled.div`
  display: flex;
  flex-direction: column;
`
export const InfoLayout = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-between;
`

