import React from "react";
import styled from "styled-components";

export const VideoContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap:10px;
`

export const VideoTagContainer = styled.div<{width: number}>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: ${p=> p.width}px;
`