import React, {useEffect, useRef, useState} from "react";
import {
    ImgModalOverlay,
    ImgModal,
    Image,
    OperationContainer,
    IconBackground,
    ScrollableContent,
    CloseContainer
} from "./css";
import useAllStates from "../../hooks/useAllStates";
import {LeftCircleOutlined, RightCircleOutlined, PlusCircleOutlined, MinusCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import useWindowSize from "../../hooks/useWindowSize";
import {MediumDeviceWidth, SmallDeviceWidth} from "../../common/constants";

interface ImgViewerProps {
    isOpen: boolean;
    imgList: string[];
    startIndex: number;
    closeEvent: ()=> void;
}

export default function ImgViewer(props: ImgViewerProps) {
    const {theme} = useAllStates()
    const {width} = useWindowSize();
    const imageRef = useRef<HTMLDivElement>(null);
    const [currentIndex, setCurrentIndex] = useState(props.startIndex)

    const ImgModalW = width< SmallDeviceWidth? '85%': width< MediumDeviceWidth? '80%': '95%'
    const ImgModalH = width< SmallDeviceWidth? '80%': width< MediumDeviceWidth? '70%': '95%'

    const ratio = Number(ImgModalW.replace('%', ''))/100
    const [imageWidth, setImageWidth] = useState(width* ratio)
    useEffect(() => {
        setCurrentIndex(props.startIndex)
        setImageWidth(width* ratio)
    }, [props.startIndex]);
    const onNextImage=()=>{
        setImageWidth(width* ratio)
        const len = props.imgList.length
        setCurrentIndex((currentIndex + 1) % len);
    }
    const onPreviousImage = ()=>{
        setImageWidth(width* ratio)
        const len = props.imgList.length
        setCurrentIndex((currentIndex - 1 + len) % len);
    }
    const onScaleUp= ()=>{
        setImageWidth(imageWidth*1.2)
    }
    const onScaleDown = ()=>{
        setImageWidth(imageWidth*0.8)
    }

    let isDragging: boolean = false;
    let startX: number = 0;
    let startY: number = 0;
    let scrollLeft: number = 0;
    let scrollTop: number = 0;

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        isDragging = true;
        startX = e.pageX - (imageRef.current?.offsetLeft || 0);
        startY = e.pageY - (imageRef.current?.offsetTop || 0);
        scrollLeft = imageRef.current?.scrollLeft || 0;
        scrollTop = imageRef.current?.scrollTop || 0;
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isDragging || !imageRef.current) return;
        e.preventDefault();
        const x = e.pageX - (imageRef.current.offsetLeft || 0);
        const y = e.pageY - (imageRef.current.offsetTop || 0);
        const walkX = x - startX;
        const walkY = y - startY;
        imageRef.current.scrollLeft = scrollLeft - walkX;
        imageRef.current.scrollTop = scrollTop - walkY;
    };

    const handleMouseUp = () => {
        isDragging = false;
    };
    return (
        <>
            {props.isOpen && (
                <ImgModalOverlay align={width< SmallDeviceWidth? 'flex-start': 'center'}>
                    <ImgModal width={ImgModalW} height={ImgModalH}>
                        <CloseContainer onClick={props.closeEvent}><CloseCircleOutlined style={{fontSize: 27}} /></CloseContainer>
                        <ScrollableContent ref={imageRef} onMouseDown={handleMouseDown}
                                           onMouseMove={handleMouseMove}
                                           onMouseUp={handleMouseUp}>
                            <Image width={imageWidth} src={props.imgList[currentIndex]}/>
                        </ScrollableContent>
                        <OperationContainer>
                            <IconBackground>
                                {currentIndex+1}/{props.imgList.length}
                            </IconBackground>
                            <IconBackground>
                                <MinusCircleOutlined style={{fontSize: 27}} onClick={onScaleDown} />
                                <PlusCircleOutlined style={{fontSize: 27}} onClick={onScaleUp} />
                            </IconBackground>
                            <IconBackground>
                                <LeftCircleOutlined style={{fontSize: 27}} onClick={onPreviousImage}/>
                                <RightCircleOutlined style={{fontSize: 27}} onClick={onNextImage}/>
                            </IconBackground>
                        </OperationContainer>
                    </ImgModal>
                </ImgModalOverlay>
            )}
        </>
    )
}