import React from "react";
import useWindowSize from "../../../hooks/useWindowSize";

// @ts-ignore
export function ImgConfig(props, clickEvent) {
    console.log('props.src', props.src)
    const {width: OuterWidth} = useWindowSize();
    const altMatch = props.alt?.match(/[^@]*(?=@)/)
    let alt: string = ''
    if (altMatch) alt = altMatch[0]

    const widthMatch = props.alt?.match(/@width=(\d+)/);
    const width = widthMatch ? widthMatch[1] : null;

    // 提取 height 变量
    const heightMatch = props.alt?.match(/#height=(\d+)/);
    const height = heightMatch ? heightMatch[1] : null;
    const config: any = {}
    if (width) {
        config.width = `${Number(width)> OuterWidth? OuterWidth: width}px`
        config.maxWidth = '100%'
        config.height = 'auto'
        if (height) {
            config['object-fit'] = 'cover'
            config.height = `${height}px`
        }
    }else{
        config.width = '100%'
    }
    const imagClick = ()=>{
        console.log('src', props.src)
        clickEvent && clickEvent(props.src);
    }
    return <img src={props.src} alt={props.alt} style={config} onClick={imagClick}/>;
}