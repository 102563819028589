import React from "react";
import { Button, Result } from 'antd';
import {NotFoundContainer} from "./css";
import useAllStates from "../../hooks/useAllStates";
import {Theme} from "../../common/constants";
import {useNavigate} from "react-router-dom";

export default function NotFound(){
    const {theme} = useAllStates()
    const navigate = useNavigate()
    const Title = ()=>{
        return <div style={{color: theme === Theme.day ? "black": "white"}}>
            404
        </div>
    }
    const SubTitle = ()=>{
        return <div style={{color: theme === Theme.day ? "black": "white"}}>
            Sorry, 你似乎访问了不存在的页面
        </div>
    }
    return(
        <NotFoundContainer>
            <Result
                status="404"
                title=<Title/>
                subTitle=<SubTitle/>
                extra={<Button danger onClick={()=> navigate("/home")}>返回首页</Button>}
            />
        </NotFoundContainer>
    )
}