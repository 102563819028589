import React from "react";
import ReactMarkdown from 'react-markdown';

interface MarkdownVideoProps{
    url: string
    width: number
}
export default function MarkdownVideo(p: MarkdownVideoProps){
    if (!p.url) return null;
    const videoId = p.url.split('v=')[1];
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    const newW = p.width
    const newH = (9*p.width)/16
    return (
        <div>
            <iframe
                width={newW}
                height={newH}
                src={embedUrl}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </div>
    );
}

