import styled, {createGlobalStyle} from "styled-components";
import {IconColor, Theme} from "../../common/constants";

export const ArticleContainer = styled.div<{theme: Theme,  width: number}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: ${p=>p.width}px;
  font-family: "Microsoft YaHei", sans-serif;
  font-size: 20px;
  color: ${p=> p.theme === Theme.night? 'white': 'black'};
    background: ${p=> p.theme === Theme.night? '#313536': '#e5e9ee'};
    padding: 12px;
    border-radius: 10px;
`

const darkColor = IconColor.a
const brightColor = IconColor.b
const brightCodeColor = '#e0d5d9'
const darkCodeColor = '#4a484b'
export const GlobalStyle = createGlobalStyle<{mode: Theme}>`
    a {
        color: ${p => p.mode === Theme.night ? darkColor : brightColor};
    }

    .task-list-item {
        list-style: none; /* 移除默认的列表样式 */
    }

    .task-list-item input[type="checkbox"] {
        /* 隐藏默认的复选框 */
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: 2px solid #ccc; /* 设置边框 */
        border-radius: 4px; /* 设置圆角 */
        width: 16px; /* 设置宽度 */
        height: 16px; /* 设置高度 */
        margin-right: 8px; /* 增加右边距 */
        vertical-align: middle; /* 垂直居中 */
    }

    .task-list-item input[type="checkbox"]:checked {
        background-color: ${p => p.mode === Theme.night ? darkColor : brightColor}; /* 设置选中时的背景颜色 */
        border-color: ${p => p.mode === Theme.night ? darkColor : brightColor}; /* 设置选中时的边框颜色 */
    }

    li {
        list-style-type: disc; /* 移除默认的列表样式 */
        margin-top: 5px;
        color: ${p=>p.mode === Theme.night? '#e1dede': '#1c1b1b'};
    }

    li::marker {
        color: ${p => p.mode === Theme.night ? darkColor : brightColor}; /* 设置紫色 */
        /*font-size: 24px; !* 设置大小 *!*/
        unicode-bidi: isolate;
        font-variant-numeric: tabular-nums;
        text-transform: none;
        text-indent: 0 !important;
        text-align: start !important;
        text-align-last: start !important;
    }

    ul {
        margin-left: 30px;
        font-size: 17px;
        color: ${p=>p.mode === Theme.night? '#e1dede': '#1c1b1b'};
    }

    p {
        font-size: 17px;
        margin-top: 8px;
        margin-bottom: 8px;
        color: ${p=>p.mode === Theme.night? '#e1dede': '#1c1b1b'};
    }

    blockquote {
        position: relative;
        padding: 8px;
        margin: 10px 0;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.1); /* 背景颜色和透明度 */
        color: ${p=>p.mode === Theme.night ? '#f5eaea' : '#1a1a1a'}; /* 文字颜色 */
        font-size: 17px;
        line-height: 1.5;
        font-style: italic;
        backdrop-filter: blur(10px); /* 毛玻璃效果 */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 阴影效果 */
        border: 1px solid rgba(255, 255, 255, 0.2); /* 边框 */
        border-left: 5px solid #f64d4d; /* 左侧竖线样式 */
    }
    

    h1, h2, h3, h4, h5, h6 {
        margin-top: 10px;
        margin-left: 5px;
        position: relative;
    }

    h1::before, h2::before, h3::before, h4::before, h5::before, h6::before {
        content: ''; /* 添加内容，触发伪元素的显示 */
        position: absolute; /* 绝对定位 */
        top: 50%; /* 将竖线的顶部定位在标题的中心 */
        left: -15px; /* 调整位置，使竖线位于标题左侧 */
        transform: translateY(-50%); /* 垂直居中 */
        height: 80%; /* 与标题高度相同 */
        width: 8px; /* 竖线宽度 */
        background-color: ${p => p.mode === Theme.night ? '#ef2e0d' : '#fa2222'}; /* 竖线颜色 */
        border-radius: 5px;
    }

    h1 {
        font-size: 27px;
    }

    h2 {
        font-size: 24px;
    }

    h3 {
        font-size: 21px;
    }

    h4 {
        font-size: 18px;
    }

    pre {
            // background: ${p => p.mode === Theme.night ? darkCodeColor : brightCodeColor};
        border-radius: 10px;
        width: 99%;
        font-size: 17px;
        overflow-x: auto; /* 当内容过长时出现水平滚动条 */
        white-space: pre-wrap; /* 保留换行符，自动换行 */
        word-wrap: break-word; /* 当单词过长时自动换行 */
        padding: 10px 0 10px 5px !important;
    }

    img {
        border-radius: 10px;
        transition: opacity transform 0.3s;
        opacity: 1;
        &:hover{
            transform: scale(1.01);
            opacity: 0.95;
        }
    }
`
export const ArticleTitle = styled.div`
  font-family: "Microsoft YaHei", sans-serif;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
`