import React, {useEffect, useState} from "react";
import {CardContainer} from "./css/layout";
import useAllStates from "../../hooks/useAllStates";
import {DateIcon, InfoContainer, InfoLayout, SmallText, TitleContainer} from "./css/innerLayout";
import {Image} from "./css/imageLayout";
import {
    CardLenObj,
    LargeDeviceWidth,
    MediaType,
    MediumDeviceWidth,
    RoutePath,
    SmallDeviceWidth
} from "../../common/constants";
import {useNavigate} from "react-router-dom";
import {TagApiData} from "../../common/interfaces";
import {LogEnum, saveLog} from "../../api/log";
import useWindowSize from "../../hooks/useWindowSize";
import {calculateCardLen} from "../../common/utils";

interface CardProps{
    id: string |number;
    src: string;
    mediaType: MediaType
    title: string
    date: string
    readCount: number,
    shortLink: string,
    tagList: TagApiData[],
}
export default function Card(p: CardProps){
    const {theme} = useAllStates()
    const {width} = useWindowSize()
    const [cardLen, setCardLen] = useState(300)
    const navigate = useNavigate();
    const [isFlipped, setIsFlipped] = useState(false);
    const onClick = ()=>{
        setIsFlipped(true);
        setTimeout(()=>{
            const routerPrefix = p.mediaType === MediaType.video? RoutePath.VIDEO: RoutePath.ARTICLE
            navigate(`/${routerPrefix}/${p.shortLink}`);
            p.mediaType === MediaType.video? saveLog(LogEnum.VideoCardMC, p.shortLink): saveLog(LogEnum.ArticleCardMC, p.shortLink)

            setIsFlipped(false);
        }, 400)
    }
    useEffect(()=>{
        if(width< SmallDeviceWidth){
            setCardLen(calculateCardLen(width,2))
        }else if (width< MediumDeviceWidth){
            setCardLen(CardLenObj[MediumDeviceWidth])
        }else
            setCardLen(CardLenObj[LargeDeviceWidth])
    }, [width])
    return(
        <CardContainer isSmallDevice={width<SmallDeviceWidth} cardLen={cardLen} onClick={onClick} isflipped={isFlipped} mediatype={p.mediaType} theme={theme}>
            <Image  src={p.src} mediaType={p.mediaType} tagList={p.tagList} onClick={onClick}/>
            <div>
                <TitleContainer theme={theme}>{p.title}</TitleContainer>
                <InfoLayout>
                    <InfoContainer>
                        <DateIcon theme={theme} fontSize={width< SmallDeviceWidth? 13: 15}/>
                        <SmallText theme={theme} fontSize={width< SmallDeviceWidth? 13: 15}>{p.date}</SmallText>
                    </InfoContainer>
                    {/*<InfoContainer>*/}
                    {/*    <ReadIcon theme={theme}/>*/}
                    {/*    <SmallText theme={theme}>{p.readCount}</SmallText>*/}
                    {/*</InfoContainer>*/}
                </InfoLayout>
            </div>
        </CardContainer>
    )
}