import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import VideoList from "../video/videoList";
import ArticleList from "../article/articleList";
import {SearchContainer} from "./css";
import {LogEnum, saveLog} from "../../api/log";

export function Search(){
    const { type } = useParams();

    useEffect(()=>{
        saveLog(LogEnum.SearchMV, type ==='video'? 'video': 'article')
    }, [])

    return(
        <SearchContainer>
            {type === 'video' && <VideoList/>}
            {type === 'article' && <ArticleList/>}
        </SearchContainer>
    )
}