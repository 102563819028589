import React from "react";
import styled, {css} from "styled-components";
import {Theme} from "../../common/constants";

export const AppContainer = styled.div<{theme: Theme}>`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  background-image: ${p=> p.theme === Theme.day? 'linear-gradient(147deg, #f9fcff 0%, #dee4ea 74%)': 'linear-gradient(315deg, #2d3436 0%, #212526 74%)'};
`

export const BodyContainer =   styled.div<{top: number}>`
  margin-top: ${p=>p.top}px;
  display: flex;
  flex-direction: column;
  align-items: center;
`