import React from "react";
import {
    AdBlockerIcon,
    AdBlockerIconContainer,
    Modal,
    ModalContent,
    ModalOverlay,
    MoreContainer, MoreIconContainer,
    Paragraph,
    Title
} from "./css";
import {Button, Tabs} from 'antd';
import type { TabsProps } from 'antd';
import {MoreOutlined} from '@ant-design/icons'
import useAllStates from "../../hooks/useAllStates";
import {useNavigate} from "react-router-dom";
interface ModalProps{
    isOpen: boolean;
}
const ab = 'https://www.gstatic.com/fundingchoices/allowads/blockers/chrome/ab_icon-1.svg'
const abp = 'https://www.gstatic.com/fundingchoices/allowads/blockers/chrome/abp_icon-1.svg'
const uo = 'https://www.gstatic.com/fundingchoices/allowads/blockers/chrome/uo_icon-1.svg'


export default function AdBlocker(p: ModalProps){
    const {theme} = useAllStates()
    const onChange = (key: string) => {
        // console.log(key);
    };

    const AD: React.FC<{url: string, title: string}> = ({url, title})=>{
        return<AdBlockerIconContainer theme={theme}>
            <AdBlockerIcon src={url} width="50"/>
            <div>{title}</div>
        </AdBlockerIconContainer>
    }

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: <AD url={ab} title={"AdBlock"}/>,
            children: <div><Paragraph theme={theme}>① 在浏览器右上角扩展程序区域内，点击 AdBlock 图标</Paragraph>
                <Paragraph theme={theme}>② 选择总是在该网站上暂停使用。</Paragraph></div>
        },
        {
            key: '2',
            label: <AD url={abp} title={"Adblock Plus"}/>,
            children: <div>
                <Paragraph theme={theme}>① 在浏览器右上角扩展程序内，点击 Adblock Plus 图标</Paragraph>
                <Paragraph theme={theme}>② 将电源图标滑倒左侧</Paragraph>
            </div>,
        },
        {
            key: '3',
            label: <AD url={uo} title={"uBlock Origin"}/>,
            children: <div>
                <Paragraph theme={theme}>① 在浏览器右上角扩展程序内，点击 uBlock Origin 图标</Paragraph>
                <Paragraph theme={theme}>② 点击电源按钮，将其置灰</Paragraph>
            </div>,
        },
        {
            key: '4',
            label: <MoreContainer><MoreIconContainer><MoreOutlined style={{fontSize: 28, fontWeight: "bolder"}} /></MoreIconContainer><div>others</div></MoreContainer>,
            children: <div>
                <Paragraph theme={theme}>① 检查浏览器扩展程序，查看是否存在类似到广告拦截器</Paragraph>
                <Paragraph theme={theme}>② 在菜单或者设置选项里面，有针对广告停用功能</Paragraph>
                <Paragraph theme={theme}>③ 在确认设置完毕后，重新刷新该页面</Paragraph>
            </div>,
        },
    ];
    const refreshPage = () => {
        window.location.reload()
    };
    return(
        <div>{
            p.isOpen && (
                <ModalOverlay>
                    <Modal theme={theme} onClick={(e)=> e.stopPropagation()}>
                        <ModalContent>
                            <Title theme={theme}>检测到您使用了广告拦截插件，该网站需要广告资助才能运营，感谢理解🙏</Title>
                            <Tabs centered defaultActiveKey="1" items={items} onChange={onChange} />
                            <Button danger onClick={refreshPage}>重新刷新</Button>
                        </ModalContent>
                    </Modal>
                </ModalOverlay>
            )
        }</div>
    )


}