// 导入 Axios
import axios, {AxiosResponse} from 'axios';
// 定义请求的 BaseURL
function getBaseUrl() {
    const path = window.location.href
    return path.includes('localhost') || path.includes('192.168')? 'http://192.168.4.53:8080': 'https://novatechdata.up.railway.app'
}

function concatenateObj(url: string, params: Record<string, any>) {
    // 检查 params 是否为空对象
    if (Object.keys(params).length === 0) {
        return getBaseUrl() + url;
    }

    const prefix = getBaseUrl() + url;
    let str = '';
    let i = 0;
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const sign = i === 0 ? '?' : '&';
            const encodedValue = encodeURIComponent(params[key]);
            str += `${sign}${key}=${encodedValue}`;
            i++;
        }
    }
    return prefix + str;
}

export function getData<T>(url: string, params: object): Promise<T> {
    const finalUrl = concatenateObj(url, params)

    return new Promise((resolve, reject) => {
        axios.get(finalUrl).then((res: AxiosResponse) => {
            resolve(res.data)
        }).catch((error: any) => {
            reject({})
            console.log('error', error)
        })
    })
}

export function postData<T>(url: string, params: object): Promise<T> {
    const finalUrl = getBaseUrl() + url
    return new Promise((resolve, reject) => {
        axios.post(finalUrl, params).then((res: AxiosResponse) => {
            resolve(res.data)
        }).catch(error => {
            reject({})
            console.log('error', error)
        })
    })
}