import React from "react";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import {darcula} from "react-syntax-highlighter/dist/esm/styles/prism";

const style = darcula
interface CodeBlockProps {
    language: string;
    value: string;
}

const CodeBlock: React.FC<CodeBlockProps> = ({ language, value }) => (
    <SyntaxHighlighter language={language} style={style}>
        {value}
    </SyntaxHighlighter>
);

// @ts-ignore
export function code({ node, inline, className, children, ...props }) {
    const match = /language-(\w+)/.exec(className || '');
    return !inline && match ? (
        <CodeBlock
            language={match[1]}
            value={String(children).replace(/\n$/, '')}
        />
    ) : (
        <code className={className} {...props}>
            {children}
        </code>
    );
}