import React, {useEffect} from "react";
import VideoList from "../video/videoList";
import ArticleList from "../article/articleList";
import HomeTagList from "../../components/tag/homeTagList";
import {LogEnum, saveLog} from "../../api/log";
export function Home(){
    useEffect(()=>{
        saveLog(LogEnum.HomeMV, '0')
    }, [])

    return(
        <>
            <HomeTagList/>
            <VideoList/>
            <ArticleList/>
        </>
    )
}