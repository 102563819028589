import React from "react";
import YouTube from 'react-youtube';

interface VideoConfig{
    width: number| string;
    height: number| string;
    videoId: string;
}

export default function VideoPlayer(p: VideoConfig){
    const opts = {
        height: p.height,
        width: p.width,
        playerVars: {
            autoplay: 1,
        },
    }
    return(
        <YouTube videoId={p.videoId} opts={opts}/>
    )
}