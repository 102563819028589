import {CardBorder, CardGap} from "./constants";


export function extractImgList(text: string): string[] {
    const regex = /!\[.*?\]\((.*?)\)/g;
    const imageLinks = [];
    let match;

    while ((match = regex.exec(text)) !== null) {
        imageLinks.push(match[1]);
    }
    return imageLinks;
}

export function getCardContainerWidth(cardLen: number, cardNum: number,cardGap: number, cardBorder: number): number {
    return cardLen*cardNum+cardGap*(cardNum-1)+cardNum*2*cardBorder
}

export function calculateCardLen(width: number,cardNum: number, cardGap: number = CardGap, cardBorder: number= CardBorder): number{
    const margin = 20
    return (width - (cardNum-1)*cardGap+cardNum*2*cardBorder-margin)/cardNum;
}