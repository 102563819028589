import {IconProps} from "./interfaces";
import {css} from "styled-components";

export const iconStyle = (p: IconProps) => {
    return css`
      width: ${p.size}px;
      height: ${p.size}px;

      & path {
        fill: ${p.color};
      }
      &:hover {
        cursor: pointer;
      }
    `
}
// '#343636' : '#faf8f8'
// #ff2424