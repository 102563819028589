import React from "react";
import styled from "styled-components";
import {IconColor, Theme} from "../../common/constants";

export const RecommendCardContainer = styled.div`
  display: flex;
  width: 160px;
  height: 96px;
  border-radius: 10px;
  flex: 0 0 auto;
  position: relative;
`

export const RecommendCardListContainer = styled.div<{theme: Theme, width: number}>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Disable vertical scrolling */
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  gap: 10px;
  max-width: ${p=>p.width-30}px;
  align-items: center;
  justify-content: flex-start;
  background: ${p=> p.theme === Theme.night? '#1f1f21': '#c9ced0'};
  margin-bottom: 30px;
  padding: 8px 20px 8px 20px;
  border-radius: 10px;
`

export const RecommendImg = styled.img.attrs(props => ({
    src: props.src
}))`
  width: ${props=> props.width}px;
  height: ${props=> props.height}px;
  object-fit: cover;
  border-radius: 10px ;
`;

export const RecommendTitleContainer = styled.div<{ isHover: boolean}>`
  color: white;
  opacity: ${props => props.isHover ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
  font-size: 14px;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0 ;
  background-image: linear-gradient(to bottom, rgba(12, 12, 12, .8) 0, transparent 100px);
`

export const RecommendTitle = styled.div`
  color: white;
  font-size: 12px;
  font-family: "Microsoft YaHei", sans-serif;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* 设置显示的行数 */
  white-space: normal;
  line-height: 1.3;
`
export const ArrowContainer = styled.div<{pos: string, theme: Theme}>`
  display: flex;
  position: absolute; /* 使用position: sticky来保持固定位置 */
  top: 42px; /* 居中垂直方向 */
  ${p=> p.pos}: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background:  ${p=> p.theme === Theme.night? '#333333': '#e0d8d8'};
  z-index: 1;
  align-items: center;
  justify-content: center;
  &:hover{
    cursor: pointer;
    background:  ${p=> p.theme === Theme.night? '#605d5d': '#f1ecec'};
  }
`

export const ListContainer = styled.div`
  display: flex;
  position: relative;
`

export const RecommendContainer = styled.div`
  display: flex;
  margin-top: 0px;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const RecommendText = styled.div<{ theme: Theme}>`
  display: flex;
  font-family: "Microsoft YaHei", sans-serif;
  font-size: 16px;
  color: ${p=> p.theme === Theme.day? '#656161': '#f1eeee'};
  margin-bottom: 5px;
`