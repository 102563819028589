import { useEffect, useState } from "react";
import {useLocation} from "react-router-dom";

export const useDetectAdBlock = () => {
    const [adBlockDetected, setAdBlockDetected] = useState(false);
    const location = useLocation();
    useEffect(() => {
        // grab a domain from https://github1s.com/gorhill/uBlock/blob/master/docs/tests/hostname-pool.js
        const url = "https://www3.doubleclick.net";
        fetch(url, {
            method: "HEAD",
            mode: "no-cors",
            cache: "no-store",
        })
            .then(({ redirected }) => {
                if (redirected) setAdBlockDetected(true);
            })
            .catch(() => {
                setAdBlockDetected(true);
            });
    }, [location]);

    return adBlockDetected;
};