import React, {useEffect, useState} from "react";
import {useParams} from 'react-router-dom';
import Markdown from 'react-markdown'
import useAllStates from "../../hooks/useAllStates";
import {ArticleContainer, ArticleTitle, GlobalStyle} from "./css";
import remarkGfm from "remark-gfm";
import {code} from "./config/codeConfig";
import {videoConfig} from "./config/videoConfig";
import {ImgConfig} from "./config/imgConfig";
import {ArticleData, ArticleInfo} from "../../common/interfaces";
import {getData} from "../../api/config";
import RecommendCardList from "../../components/recommend/recommendCardList";
import SimpleTagList from "../../components/tag/simpleTagList";
import {MediaType, MediumDeviceWidth, SmallDeviceWidth} from "../../common/constants";
import {LogEnum, saveLog} from "../../api/log";
import {extractImgList} from "../../common/utils";
import ImgViewer from "../../components/imgViewer/imgViewer";
import useWindowSize from "../../hooks/useWindowSize";
import rehypeRaw from 'rehype-raw';

export default function Article(){
    const { id: articleId } = useParams();
    const [showImgViewer, setShowImgViewer] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const {theme} = useAllStates()
    const {width} = useWindowSize();
    const [newW, setNewW] = useState(640);
    useEffect(() => {
        if(width<SmallDeviceWidth){
            setNewW(width*0.9)
        }else if(width< MediumDeviceWidth){
            setNewW(width*0.8)
        }else{
            setNewW(width*0.7)
        }
    }, [width]);

    const [articleData, setArticleData] = useState<ArticleData>()
    const [tagIdList, setTagIdList] = useState('')
    useEffect(()=>{
        getData<ArticleInfo>('/article/queryArticleByArticleId', {aid: articleId}).then((res)=>{
            setArticleData({...res.article, tagList: res.tagList})
            res.tagList && setTagIdList(res.tagList.map(t=> t.id.toString()).join(','))
        })
        articleId && saveLog(LogEnum.ArticleMV, articleId)
    }, [articleId])
    const clickEvent = (src: string)=>{
        const list = extractImgList(articleData?.content ?? '')
        setStartIndex(list.indexOf(src))
        setShowImgViewer(true)
    }
    return(
        <ArticleContainer theme={theme}  width={newW}>
            <ImgViewer isOpen={showImgViewer} imgList={extractImgList(articleData?.content ?? '')} startIndex={startIndex} closeEvent={()=> setShowImgViewer(false)}/>
            <SimpleTagList region={'onContent'} tagList={articleData?.tagList ?? []}/>
            <ArticleTitle>{articleData?.title ?? ''}</ArticleTitle>
            <GlobalStyle mode={theme} />
            {/* @ts-ignore */}
            <Markdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}  components={
                {
                    // @ts-ignore
                    code,
                    a: ({ node,...props }) => videoConfig({node, props, width: newW}),
                    img: ({ node, ...props }) => ImgConfig(props, clickEvent)
                }
            }
            >{articleData?.content ?? ''}</Markdown>
            <RecommendCardList width={newW} mediaType={MediaType.article} tagIdList={tagIdList} mediaId={articleData?.id || -1}></RecommendCardList>
        </ArticleContainer>
    )
}