import React, {useEffect, useState} from "react";
import {useLocation, useParams} from 'react-router-dom';
import VideoPlayer from "../../components/videoPlayer/videoPlayer";
import { VideoContainer, VideoTagContainer} from "./css";
import {VideoData, VideoInfo as VideoInfoType} from "../../common/interfaces";
import {getData} from "../../api/config";
import RecommendCardList from "../../components/recommend/recommendCardList";
import useAllStates from "../../hooks/useAllStates";
import SimpleTagList from "../../components/tag/simpleTagList";
import VideoInfo from "../../components/videoPlayer/VideoInfo";
import {MediaType, MediumDeviceWidth, SmallDeviceWidth} from "../../common/constants";
import {LogEnum, saveLog} from "../../api/log";
import useWindowSize from "../../hooks/useWindowSize";

export default function Video(){
    const { id: videoId } = useParams();
    const {width} = useWindowSize();
    const [size, setSize] = useState({
        width: 640,
        height: 360
    });
    useEffect(() => {
        const ratio = 9/16
        if(width< SmallDeviceWidth){
            setSize({width: width*0.96, height: width*0.96*ratio})
        }else if(width < MediumDeviceWidth){
            setSize({width: width*0.80, height: width*0.80*ratio})
        }else{
            setSize({width: width*0.70, height: width*0.70*ratio})
        }
    }, [width]);

    const [videoData, setVideoData] = useState<VideoData>()
    const [tagIdList, setTagIdList] = useState('')

    useEffect(()=>{
        getData<VideoInfoType>('/video/queryVideoByVideoId', { vid: videoId }).then((res)=>{
            setVideoData({...res.video, tagList: res.tagList})
            res.tagList && setTagIdList(res.tagList.map((t) => t.id.toString()).join(','))
        })
        videoId && saveLog(LogEnum.VideoMV, videoId)
    }, [videoId])

    return(
        <VideoContainer>
            <VideoTagContainer width={size.width}><SimpleTagList region={'onContent'} tagList={videoData?.tagList?? []}/></VideoTagContainer>
            <VideoInfo date={videoData?.createTime} width={size.width} src={videoData?.headImage ?? ''} title={videoData?.title ?? ''}/>
            <VideoPlayer width={size.width} height={size.height} videoId={videoId ?? ''} />
            <RecommendCardList width={size.width} mediaType={MediaType.video} tagIdList={tagIdList} mediaId={videoData?.id ?? -1}></RecommendCardList>
        </VideoContainer>
    )
}
