import React from "react";
import {BottomContainer, NavigateBackContainer} from "./css";
import useAllStates from "../../hooks/useAllStates";
import {ArticleContainer, ArticleIcon, ArticleName, VideoContainer, VideoIcon, VideoName} from "../header/css";
import {RoutePath} from "../../common/constants";
import {LogEnum, saveLog} from "../../api/log";
import {useNavigate} from "react-router-dom";
import {LeftCircleOutlined} from "@ant-design/icons";

export default function Bottom(){
    const {theme} = useAllStates()
    const navigate = useNavigate();
    const onVideoButtonClick = ()=>{
        navigate(`/${RoutePath.SEARCH}/video`)
        saveLog(LogEnum.VideoButtonMC, '1')
    }
    const onArticleButtonClick = ()=>{
        navigate(`/${RoutePath.SEARCH}/article`)
        saveLog(LogEnum.ArticleButtonMC, '2')
    }
    return(
        <BottomContainer theme={theme}>
            <NavigateBackContainer onClick={()=> navigate(-1)}><LeftCircleOutlined style={{fontSize: 25}}/></NavigateBackContainer>
            <ArticleContainer onClick={onArticleButtonClick}>
                <ArticleIcon size={20}/>
                <ArticleName theme={theme}>文章</ArticleName>
            </ArticleContainer>
            {/*<VideoContainer onClick={onVideoButtonClick}>*/}
                {/*    <VideoIcon size={20}/>*/}
                {/*    <VideoName theme={theme}>视频</VideoName>*/}
                {/*</VideoContainer>*/}

        </BottomContainer>
    )
}