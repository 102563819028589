import React, {useEffect, useRef, useState} from "react";
import {RedArrowContainer, StickyTagWrapper, TagListContainer, TagOuterContainer} from "./css";
import Tag from "./tag";
import {TagProps} from "../../common/interfaces";
import {getData} from "../../api/config";
import {CaretLeftOutlined, CaretRightOutlined} from "@ant-design/icons";
import useScrollButton from "../../hooks/useScrollButton";
import useAllStates from "../../hooks/useAllStates";
import {useDispatch} from "react-redux";
import {setTagIdList} from "../../store/reducer/mainFilter";
import {useLocation} from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";
import {MediumDeviceWidth} from "../../common/constants";


export default function HomeTagList() {
    const {theme, tagIdList} = useAllStates()
    const dispatch = useDispatch()
    const location = useLocation()
    const {width} = useWindowSize()
    const [array, setArray] = useState<TagProps[]>([])
    const {listContainerRef, showLeftArrow, showRightArrow, scrollRecommendList} = useScrollButton(180)
    const [tagListWidth, setTagListWidth] = useState<number>(MediumDeviceWidth-50)
    const getTagCountList = () => {
        let kind = location.pathname.includes('video') ? 'video' : location.pathname.includes('article') ? 'article' : 'all';
        getData<TagProps[]>('/tag/getTagCountList', {kind: kind}).then((res) => {
            const ints =tagIdList.length> 0? tagIdList.split(',').map((t)=> parseInt(t)): []
            const arr: TagProps[] = [{id: -1, name: '全部', active: ['-1', ''].indexOf(ints.join(',')) >= 0}]
            // @ts-ignore
            const leftArr = res.filter(t=> t.count > 0).map((tag) => ({...tag, active: ints.indexOf(tag.id)>=0}))
            setArray(arr.concat(leftArr))
        })
    }
    useEffect(() => {
        if(width< MediumDeviceWidth){
            setTagListWidth(610 * width/MediumDeviceWidth)
        }else
            setTagListWidth(MediumDeviceWidth-50)
    }, [width]);
    useEffect(() => {
        getTagCountList()
    }, [])

    const onClick = (id: number) => {
        const newArray: TagProps[] = []
        array.forEach((tag) => {
            if (id === -1) {
                if (tag.id === -1) {
                    newArray.push({...tag, active: true})
                } else
                    newArray.push({...tag, active: false})
            } else {
                if (tag.id === -1) {
                    newArray.push({...tag, active: false})
                } else {
                    if (tag.id === id)
                        newArray.push({...tag, active: !tag.active})
                    else
                        newArray.push({...tag})
                }
            }
        })
        const activeLength = newArray.filter((tag) => tag.active).length
        if (activeLength === 0 && array.length > 0) {
            newArray[0].active = true
        }
        const idList = newArray.filter(t=> t.active).map(t=> t.id).join(',')
        dispatch(setTagIdList(idList))
        setArray(newArray)
    }


    return (
        <TagOuterContainer theme={theme}>
            {showLeftArrow && <RedArrowContainer pos={'left'} theme={theme} onClick={() => scrollRecommendList('left')}>
                <CaretLeftOutlined style={{color: 'red'}}/>
            </RedArrowContainer>}
            <TagListContainer ref={listContainerRef} width={tagListWidth}>
                {
                    array.map((tag, index) => (
                        <>{index === 0? <StickyTagWrapper>
                            <Tag id={tag.id} name={tag.name} active={tag.active} onClick={onClick}/>
                        </StickyTagWrapper>: <Tag id={tag.id} name={tag.name} active={tag.active} onClick={onClick}/>
                        }</>
                    ))
                }
            </TagListContainer>
            {showRightArrow && <RedArrowContainer pos={'right'} theme={theme} onClick={() => scrollRecommendList('right')}>
                <CaretRightOutlined style={{color: 'red'}}/>
            </RedArrowContainer>
            }
        </TagOuterContainer>

    )
}