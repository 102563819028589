import React from "react";
import {TagContainer} from "./css";
import useAllStates from "../../hooks/useAllStates";
import {TagProps} from "../../common/interfaces";
import {LogEnum, saveLog} from "../../api/log";

export default function Tag(p: TagProps){
    const {theme} = useAllStates()
    const onTagClick = ()=>{
        p?.onClick?.(p.id);
        saveLog(LogEnum.TagMC, p.name)
    }

    return(
        <TagContainer region={p.region || "onContent"} theme={theme} active={p.active} onClick={onTagClick} >{p.name} </TagContainer>
    )
}