export enum Theme {
    night = 'night',
    day = 'day'
}

export enum MediaType{
    video = 'video',
    article = 'article'
}
export enum RoutePath {
    HOME = 'home',
    SEARCH = 'search',
    VIDEO = 'video',
    ARTICLE = 'article'
}

export enum SearchType{
    ARTICLE = 'article',
    VIDEO = 'video',
    MIX = 'mix'
}

export const MainColor = '#ff2424'

export const themeValue = 'theme'


export const IconColor = {
    a: '#fc9842',
    b: '#ff2424'
}

export const VideoColor={
    borderColor: '#f3400a',
    buttonGradient: {
        color1: '#fc9842',
        color2: '#ff2424'
    },
    cardDayGradient:{
        color1: '#ffffff',
        color2: '#f0fafb'
    },
    cardNightGradient:{
        color1: '#3b3939',
        color2: '#28282c'
    },
}

export const ArticleColor={
    borderColor: '#ffa500',
    buttonGradient: {
        color1: '#ffcc00',
        color2: '#ffa500'
    },
    cardDayGradient:{
        color1: '#ffffff',
        color2: '#efebeb'
    },
    cardNightGradient:{
        color1: '#3b3939',
        color2: '#28282c'
    },
}

export const SmallDeviceWidth = 650

export const MediumDeviceWidth = 850

export const LargeDeviceWidth = 1000

export const CardLenObj={
    [MediumDeviceWidth]: 250,
    [LargeDeviceWidth]: 300,
}

export const CardGap = 8
export const CardBorder = 1