import styled from "styled-components";
import {IconColor, Theme} from "../../common/constants";

export const BottomContainer = styled.div<{theme: Theme}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: fixed;
    z-index: 8;
    width: 100vw;
    height: 50px;
    bottom: 0;
    background-color: ${p=>p.theme === Theme.day? '#ffffff':'#17202a'};
    border-bottom: ${p=> p.theme === Theme.day? '#e7e1e1': ''} 1px solid;
`

export const NavigateBackContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 50%;
    background-color: wheat;
    background-image: linear-gradient(315deg, ${IconColor.a} 0%, ${IconColor.b} 74%);
    color: white;
`