import React from "react";
import styled from "styled-components";
import {Theme} from "../../common/constants";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`

export const Modal = styled.div<{theme: Theme}>`
  
  ${p=> p.theme === Theme.day ? 'background: white;': 'background: #181a1b;'};
  border-radius: 10px;
  padding: 15px;
  width: 450px;
  max-width: 40%;
  max-height: 60%;
  overflow: auto;
  box-shadow: 0 0 10px ${p=> p.theme===Theme.day? 'rgba(0, 0, 0, 0.3)': 'rgba(255,255,255,0.15)'};
`
export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
`
export const AdBlockerIcon = styled.img.attrs(props => ({
    src: props.src
}))`
  width: ${props=> props.width}px;
  height: ${props=> props.height}px;
  object-fit: cover;
  border-radius: 10px ;
`;

export const AdBlockerIconContainer = styled.div<{theme: Theme}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  color: ${p=> p.theme === Theme.day? 'black': 'white'};
`

export const Paragraph = styled.p<{theme: Theme}>`
  font-size: 16px;
  margin-bottom: 3px;
  font-family: "Microsoft YaHei", sans-serif;
  color: ${p=> p.theme === Theme.day? 'black': '#aaa9a7'};
`
export const Title = styled.div<{theme: Theme}>`
  font-size: 19px;
  font-family: "Microsoft YaHei", sans-serif;
  color: ${p=> p.theme === Theme.day? 'black': '#e8e6e3'};
`
export const MoreIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 8px;
  width: 30px;
  height: 30px;
  background: #e8e2e2;
`
export const MoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 50%;
`
