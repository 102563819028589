import React, {useEffect, useRef, useState} from "react";
import {ArrowContainer, ListContainer, RecommendCardListContainer, RecommendContainer, RecommendText} from "./css";
import RecommendCard from "./recommendCard";
import useAllStates from "../../hooks/useAllStates";
import {LeftOutlined, RightOutlined} from '@ant-design/icons'
import {Theme} from "../../common/constants";
import {getData} from "../../api/config";
import {RecommendCardListProps, RecommendCardProps} from "../../common/interfaces";
import useScrollButton from "../../hooks/useScrollButton";

export default function RecommendCardList(p: RecommendCardListProps) {
    const {theme} = useAllStates()
    const [recommendList, setRecommendList] = useState<RecommendCardProps[]>([])
    const {listContainerRef, showLeftArrow, showRightArrow, scrollRecommendList} = useScrollButton(450)


    const iconColor = {
        color: theme === Theme.day ? 'black' : 'white',
        fontSize: '14px'
    }
    const getRecommendList = () => {
        const m = p.mediaType
        p.tagIdList && p.mediaId > -1 && getData<RecommendCardProps[]>(`/tag/getRecommendListFor${m.charAt(0).toUpperCase() + m.slice(1)}`, {
            tagIdList: p.tagIdList,
            mediaId: p.mediaId
        }).then((res) => {
            setRecommendList(res)
        })
    }
    useEffect(() => {
        getRecommendList()
    }, [p.tagIdList, p.mediaId])

    return (
        <>
            {
                recommendList.length > 0 && <RecommendContainer >
                    <RecommendText theme={theme}>相关推荐</RecommendText>
                    <ListContainer>
                        {showLeftArrow && <ArrowContainer pos={'left'} theme={theme} onClick={() => scrollRecommendList('left')}>
                            <LeftOutlined style={iconColor} />
                        </ArrowContainer>
                        }
                        <RecommendCardListContainer ref={listContainerRef} theme={theme} width={p.width}>
                            {recommendList.map((item) => {
                                return <RecommendCard image={item.image} title={item.title} id={item.id}/>
                            })}
                        </RecommendCardListContainer>
                        {showRightArrow && <ArrowContainer pos={'right'} theme={theme} onClick={() => scrollRecommendList('right')}>
                            <RightOutlined style={iconColor}/>
                        </ArrowContainer>}
                    </ListContainer>
                </RecommendContainer>
            }
        </>
    )
}