import styled from "styled-components";
import React, {useState} from "react";
import {ArticleColor, MediaType, SmallDeviceWidth, Theme, VideoColor} from "../../../common/constants";
import {ArticleIcon, VideoIcon} from "../../header/css";
import {TagApiData} from "../../../common/interfaces";
import SimpleTagList from "../../tag/simpleTagList";
import useWindowSize from "../../../hooks/useWindowSize";


const Img = styled.img.attrs(props => ({
    src: props.src,
}))`
  width: 100%;
  height: ${p=>p.width}px;
  object-fit: contain;
  border-radius: 24px 24px 0 0 ;

  
`;
const color1 =  VideoColor.buttonGradient.color1;
const color2 = VideoColor.buttonGradient.color2
const color3 =  ArticleColor.buttonGradient.color1;
const color4 = ArticleColor.buttonGradient.color2;
const gradient1 = `linear-gradient(315deg, ${color1} 0%, ${color2} 74%)`
const gradient2 = `linear-gradient(315deg, ${color3} 0%, ${color4} 74%)`
const MediaTag = styled.div<{mediaType: MediaType}>`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 24px 0 10px;
  width: 30px;
  height: 30px;
  background-image: ${p=> p.mediaType === MediaType.video? gradient1: gradient2}
`

export const ImageBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  &:hover{
    cursor: pointer;
    animation: pulse;
    animation-duration: 0.2s;
  }
`
export const OverLay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 24px 24px 0 0 ;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35); /* 设置蒙层颜色和透明度 */
  opacity: 1; /* 初始时透明 */
  transition: opacity 0.3s ease; /* 添加过渡效果 */
`

export const TagBottomContainer =styled.div`
  position: absolute;
  bottom: 5px;
  left: 5px;
`

interface ImageProps{
    src: string;
    mediaType: MediaType
    tagList: TagApiData[]
    onClick?: ()=>void
}
export const Image: React.FC<ImageProps> = (p: ImageProps)=> {
    const [isHover, setHover] = useState(false)
    const {width} = useWindowSize()
    const onMouseOver = ()=>{
        setHover(true)
    }
    const onMouseOut = ()=>{
        setHover(false)
    }
    let touchStartX = 0;
    let touchStartY = 0;

    const onTouchStart = (event: any) => {
        touchStartX = event.touches[0].clientX;
        touchStartY = event.touches[0].clientY;
    }

    const onTouchEnd = (event: any) => {
        const touchEndX = event.changedTouches[0].clientX;
        const touchEndY = event.changedTouches[0].clientY;

        const deltaX = Math.abs(touchEndX - touchStartX);
        const deltaY = Math.abs(touchEndY - touchStartY);

        if (deltaX < 5 && deltaY < 5) {
            // 触发点击事件
            setTimeout(() => {
                p.onClick && p.onClick();
            }, 200); // 设置延迟时间
        }
    }
    return(
        <ImageBox onMouseOver={onMouseOver} onMouseOut={onMouseOut} onTouchStart={onTouchStart} onTouchEnd={onTouchEnd}>
            <Img src={p.src} width={width< SmallDeviceWidth? 150: 180}/>
            {p.mediaType === MediaType.video && <MediaTag mediaType={MediaType.video}><VideoIcon size={20} /></MediaTag>}
            {p.mediaType === MediaType.article && <MediaTag mediaType={MediaType.article}><ArticleIcon size={18}/></MediaTag>}
            {isHover && <OverLay>
                {p.mediaType === MediaType.video && <VideoIcon size={48} />}
                {p.mediaType === MediaType.article && <ArticleIcon size={38}/>}
                {
                    <TagBottomContainer>
                        <SimpleTagList region={'onCard'} tagList={p.tagList}/>
                    </TagBottomContainer>
                }
            </OverLay>}
        </ImageBox>
    )
}