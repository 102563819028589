import React, {useEffect, useRef, useState} from "react";
import {CardListContainer, PageContainer} from "../../components/card/css/layout";
import Card from "../../components/card/card";
import {ArticleColor, MediaType, SmallDeviceWidth, Theme, VideoColor} from "../../common/constants";
import {getData} from '../../api/config'
import {ArticleData, ArticleInfo, Result, VideoData} from "../../common/interfaces";
import dayjs from "dayjs";
import {ConfigProvider, Pagination} from "antd";
import useAllStates from "../../hooks/useAllStates";
import HomeTagList from "../../components/tag/homeTagList";
import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setArticlePageNum} from "../../store/reducer/mainFilter";
import useWindowSize from "../../hooks/useWindowSize";

export default function ArticleList() {

    const [articleList, setArticleList] = useState<ArticleData[]>([])
    const {theme, searchValue, tagIdList, articlePageNum} = useAllStates()
    const [totalCount, setTotalCount ] = useState(0)
    const currentPath = useLocation()
    const dispatch = useDispatch()
    const isInit = useRef(true)
    const isSearchArticle = currentPath.pathname === '/search/article'
    const {width} = useWindowSize()
    const [pageSize, setPageSize] = useState(()=>{
        if(width < SmallDeviceWidth){
            return 4
        }else if(isSearchArticle){
            return 9
        }else{
            return 6
        }
    })

    const requestData = (pageNum: number, pageSize: number )=>{
        const config: Record<string, any> = {
            pageSize,
            pageNum,
            visible: true
        }
        if(searchValue){
            config['title'] = searchValue
        }
        if(['-1', ''].indexOf(tagIdList) < 0){
            config['tagIdList'] = tagIdList
        }
        getData<Result<ArticleInfo[]>>('/article/queryPagedArticle', config).then((res) => {
            const data = res.data.map<ArticleData>((a)=>  ({...a.article, tagList: a.tagList}))
            setArticleList(data)
            setTotalCount(res.totalCount)
        })
    }
    useEffect(() => {
        if(isInit.current) return
        dispatch(setArticlePageNum(1))
        requestData(1, pageSize)
    }, [searchValue, tagIdList])

    useEffect(()=>{
        isInit.current = false
        requestData(articlePageNum, pageSize)
    }, [])


    const onPageChange = (page: number, pageSize: number)=>{
        dispatch(setArticlePageNum(page))
        requestData(page, pageSize)
    }
    const color = ArticleColor.buttonGradient.color2
    const tokenObj = {
        colorBgContainer: theme === Theme.night? '#29282a': 'white',
        colorTextDisabled:  color,
        colorPrimary: color,
        colorText: color
    }
    return (
        <>
            {isSearchArticle && <HomeTagList/>}
            <CardListContainer>
                {
                    articleList.map((article) => {
                        return (
                            <>
                                <Card id={article.id} src={article.headImage} mediaType={MediaType.article} title={article.title}
                                      date={dayjs(article.createTime).format('YYYY年M月D日')}
                                      readCount={article.readCount}
                                      shortLink={article.articleId}
                                      tagList={article.tagList}
                                />
                            </>
                        )
                    })
                }
            </CardListContainer>
            {articleList.length> 0 && totalCount/pageSize>1 && <PageContainer theme={theme}>
                <ConfigProvider
                    theme={{token: tokenObj}}
                >
                    <Pagination current={articlePageNum} total={totalCount} pageSize={pageSize} showSizeChanger={false} onChange={onPageChange}/>
                </ConfigProvider>
            </PageContainer>}
        </>

    )
}