import React, {useState} from "react";
import {RecommendCardContainer, RecommendImg, RecommendTitle, RecommendTitleContainer} from "./css";
import {RecommendCardProps} from "../../common/interfaces";
import {useLocation, useNavigate} from "react-router-dom";
import {LogEnum, saveLog} from "../../api/log";

export default function RecommendCard(p: RecommendCardProps){
    const [isHover, setHover] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const onMouseOver = ()=>{
        setHover(true)
    }
    const onMouseOut = ()=>{
        setHover(false)
    }
    const onClick = ()=>{
        const prefix = location.pathname.includes('video')? 'video': 'article'
        saveLog(prefix === "video" ? LogEnum.RecommendVideoCardMC: LogEnum.RecommendArticleCardMC, String(p.id))
        navigate(`/${prefix}/${p.id}`)
    }
    return(
        <RecommendCardContainer onMouseOver={onMouseOver} onMouseOut={onMouseOut} onClick={onClick}>
            <RecommendImg src={p.image} width="160" height="98" />
            <RecommendTitleContainer isHover={isHover}>
                <RecommendTitle>{p.title}</RecommendTitle>
            </RecommendTitleContainer>
        </RecommendCardContainer>
    )
}