import React, {useEffect, useState} from 'react';
import {SpeedInsights} from '@vercel/speed-insights/react';
import Header from "../../components/header/header";
import {AppContainer, BodyContainer} from "./css";
import useAllStates from "../../hooks/useAllStates";
import Footer from "../../components/footer/footer";
import {Route, Routes, useLocation} from "react-router-dom";
import Video from "../video/video";
import Article from "../article/article";
import {MediumDeviceWidth, RoutePath} from "../../common/constants";
import {Search} from "../search/search";
import {Home} from "../home/home";
import NotFound from "../notFound/notFound";
import AdBlocker from "../../components/adBlocker/adBlocker";
import {useDetectAdBlock} from "../../hooks/useDetectAdBlock";
import Bottom from "../../components/bottom/bottom";
import useWindowSize from "../../hooks/useWindowSize";
import {LogEnum, saveLog} from "../../api/log";

function App() {
    const {theme} = useAllStates()
    const location = useLocation()
    const [showAd, setShowAd] = useState(false)
    const adBlockDetected = useDetectAdBlock();

    useEffect(() => {
        if(adBlockDetected){
            saveLog(LogEnum.AdBlocker, '0')
        }
        // setShowAd(adBlockDetected)
    }, [adBlockDetected]);
    const {width} = useWindowSize();
    const getTopNumber = ()=>{
        const path = location.pathname;
        return path.startsWith('/video/') || path.startsWith('/article/') ? 60: 120
    }
  return (
    <AppContainer theme={theme}>
        <Header/>
        <AdBlocker isOpen={showAd}/>
        <BodyContainer top={getTopNumber()}>
            <Routes>
                <Route index element={<Home/>} />
                <Route path={RoutePath.HOME} element={<Home/>} />

                <Route path={`${RoutePath.SEARCH}/:type`} element={<Search/>} />
                <Route path={`${RoutePath.VIDEO}/:id`} element={<Video />} />
                <Route path={`${RoutePath.ARTICLE}/:id`} element={<Article />} />
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </BodyContainer>
        <Footer/>
        {width< MediumDeviceWidth && <Bottom/>}
        <SpeedInsights />
    </AppContainer>
  );
}

export default App;
