import React from "react";
import {DescContainer, TitlePositionContainer, VideoImg, VideoInfoContainer, VideoTitle} from "./css";

import useAllStates from "../../hooks/useAllStates";
import {DateIcon, SmallText} from "../card/css/innerLayout";
import dayjs from "dayjs";
import {SmallDeviceWidth} from "../../common/constants";
import useWindowSize from "../../hooks/useWindowSize";

interface VideoInfoProp{
    width: number
    src: string
    title: string
    date?: number
}

export default function VideoInfo(p: VideoInfoProp){
    const {theme} = useAllStates()
    const {width} = useWindowSize()
    return(
        <VideoInfoContainer width={p.width}>
            <VideoImg src={p.src}/>
            <TitlePositionContainer>
                <VideoTitle theme={theme}>{p.title}</VideoTitle>
                <DescContainer>
                    <DateIcon theme={theme} fontSize={width< SmallDeviceWidth? 13: 15}/>
                    <SmallText theme={theme} fontSize={width< SmallDeviceWidth? 13: 15}>{dayjs(p.date).format('YYYY年M月D日')}</SmallText>
                </DescContainer>
            </TitlePositionContainer>

        </VideoInfoContainer>
    )

}