import React, {useEffect, useRef, useState} from "react";
import {CardListContainer, PageContainer} from "../../components/card/css/layout";
import Card from "../../components/card/card";
import {MediaType, SmallDeviceWidth, Theme, VideoColor} from "../../common/constants";
import {getData} from '../../api/config'
import {Result, VideoData, VideoInfo} from "../../common/interfaces";
import dayjs from "dayjs";
import { Pagination, ConfigProvider} from "antd";
import useAllStates from "../../hooks/useAllStates";
import HomeTagList from "../../components/tag/homeTagList";
import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setVideoPageNum} from "../../store/reducer/mainFilter";
import useWindowSize from "../../hooks/useWindowSize";

export default function VideoList() {

    const [videoList, setVideoList] = useState<VideoData[]>([])
    const {theme, searchValue, tagIdList, videoPageNum} = useAllStates()
    const dispatch = useDispatch()
    const [totalCount, setTotalCount ] = useState(0)
    const isInit = useRef(true)
    const currentPath = useLocation()
    const isSearchVideo = currentPath.pathname === '/search/video'
    const {width} = useWindowSize()
    const [pageSize, setPageSize] = useState(()=>{
        if(width < SmallDeviceWidth){
            return 4
        }else if(isSearchVideo){
            return 9
        }else{
            return 6
        }
    })

    const requestData = (pageNum: number, pageSize: number )=>{
        const config: Record<string, any> = {
            pageSize,
            pageNum,
        }
        if(searchValue){
            config['title'] = searchValue
        }
        if(['-1', ''].indexOf(tagIdList) < 0){
            config['tagIdList'] = tagIdList
        }
        getData<Result<VideoInfo[]>>('/video/queryPagedVideo', config).then((res) => {
            const data = res.data.map<VideoData>((v)=>  ({...v.video, tagList: v.tagList}))
            setVideoList(data)
            setTotalCount(res.totalCount)
        })
    }
    useEffect(() => {
        if(isInit.current) return
        requestData(1, pageSize)
        dispatch(setVideoPageNum(1))
    }, [searchValue, tagIdList])

    useEffect(()=>{
        isInit.current = false
        requestData(videoPageNum, pageSize)
    }, [])


    const onPageChange = (page: number, pageSize: number)=>{
        dispatch(setVideoPageNum(page))
        requestData(page, pageSize)
    }

    const tokenObj = {
        colorBgContainer: theme === Theme.night? '#282a2a': 'white',
        colorTextDisabled:  VideoColor.borderColor,
        colorPrimary: VideoColor.borderColor,
        colorText: VideoColor.borderColor
    }
    return (
        <>
            {isSearchVideo && <HomeTagList/>}
            <CardListContainer>
                {
                    videoList.map((video) => {
                        return (
                            <>
                                <Card id={video.id} src={video.headImage} mediaType={MediaType.video} title={video.title}
                                      date={dayjs(video.createTime).format('YYYY年M月D日')}
                                      readCount={video.readCount}
                                      shortLink={video.videoId}
                                      tagList={video.tagList}
                                />
                            </>
                        )
                    })
                }
            </CardListContainer>
            {videoList.length> 0 && totalCount/pageSize>1 && <PageContainer theme={theme}>
                <ConfigProvider
                    theme={{token: tokenObj}}
                >
                    <Pagination current={videoPageNum} total={totalCount} pageSize={pageSize} showSizeChanger={false} onChange={onPageChange}/>
                </ConfigProvider>
            </PageContainer>}
        </>

    )
}