import React from "react";
import styled, {css} from "styled-components";
import {Theme} from "../../common/constants";
import exp from "constants";

export const VideoImg = styled.img.attrs(props => ({
    src: props.src
}))`
  width: 60px;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  flex-shrink: 0;
`;

export const VideoInfoContainer = styled.div<{width: number}>`
  width: ${p=>p.width}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
    //background: red;
`
export const VideoTitle = styled.div<{theme: Theme}>`
  margin-left: 10px;
  font-family: "Microsoft YaHei", sans-serif;
  font-size: 18px;
  color: ${p=> p.theme === Theme.day? 'black': 'white'};

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* 设置显示的行数 */
  white-space: normal;
`
export const TitlePositionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`
export const DescContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin-left: 9px;
  
`