import React, {useEffect, useState} from "react";
import {
    ArticleContainer,
    ArticleIcon,
    ArticleName,
    ClearIcon,
    DayIcon,
    HeaderContainer,
    IconContainer,
    Input,
    InputContainer,
    InputPosition,
    LogoContainer,
    LogoIcon,
    LogoName,
    NightIcon,
    SearchIcon,
    VideoContainer,
    VideoIcon,
    VideoName
} from "./css";
import {LargeDeviceWidth, MediumDeviceWidth, RoutePath, SmallDeviceWidth, Theme} from "../../common/constants";
import useAllStates from "../../hooks/useAllStates";
import {useAppDispatch} from "../../hooks/storeHooks";
import {setSearchValue, setTheme} from "../../store/reducer/mainFilter";
import {useLocation, useNavigate} from "react-router-dom";
import {LogEnum, saveLog} from "../../api/log";
import useWindowSize from "../../hooks/useWindowSize";

export default function Header() {
    const dispatch = useAppDispatch();
    const {width} = useWindowSize();
    const navigate = useNavigate();
    const location = useLocation()
    const {theme} = useAllStates()
    const [inputWidth, setInputWidth] = useState(400)
    useEffect(() => {
        if(width< 600){
            setInputWidth(400* width/600)
        }else
            setInputWidth(400)
    }, [width]);
    const [inputValue, setInputValue] = useState('')
    const onThemeClick = () => {
        saveLog(LogEnum.ThemeButtonMC, theme === Theme.day? Theme.night: Theme.day)
        if (theme === Theme.day)
            dispatch(setTheme(Theme.night))
        else
            dispatch(setTheme(Theme.day))
    }
    const onLogoClick = ()=>{
        navigate(RoutePath.HOME)
    }
    const onVideoButtonClick = ()=>{
        navigate(`/${RoutePath.SEARCH}/video`)
        saveLog(LogEnum.VideoButtonMC, '1')
    }
    const onArticleButtonClick = ()=>{
        navigate(`/${RoutePath.SEARCH}/article`)
        saveLog(LogEnum.ArticleButtonMC, '2')
    }
    const onClear = ()=>{
        dispatch(setSearchValue(''))
        setInputValue('')
    }
    const onSearch = ()=>{
        inputValue.length> 0  && saveLog(LogEnum.SearchButtonMC, inputValue)
        dispatch(setSearchValue(inputValue))
        const path = location.pathname
        if(path.startsWith('/home')) return
        let kind = path.includes('video') ? 'video' :  'article' ;
        navigate(`/${RoutePath.SEARCH}/${kind}`)
    }
    const onInputChange = (e: any)=>{
        setInputValue(e.target.value)
    }
    const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>)=>{
        if(e.key === 'Enter'){
            onSearch()
        }
    }
    return (
        <HeaderContainer theme={theme} width={width} >
            <LogoContainer onClick={onLogoClick}>
                <IconContainer size={26}><LogoIcon/></IconContainer>
                {width> MediumDeviceWidth && <LogoName theme={theme}>诺瓦科技</LogoName>}
            </LogoContainer>
            <InputPosition width={width}>
                <InputContainer theme={theme} width={inputWidth}>
                    <SearchIcon theme={theme} onClick={onSearch}/>
                    <Input onKeyPress={onKeyPress}  value={inputValue} onChange={onInputChange} theme={theme}/>
                    <ClearIcon theme={theme} onClick={onClear}/>
                </InputContainer>
            </InputPosition>
            {
                width> MediumDeviceWidth && (
                    <>
                        <ArticleContainer onClick={onArticleButtonClick}>
                            <ArticleIcon size={20}/>
                            <ArticleName theme={theme}>文章</ArticleName>
                        </ArticleContainer>
                        {/*<VideoContainer onClick={onVideoButtonClick}>*/}
                        {/*    <VideoIcon size={20}/>*/}
                        {/*    <VideoName theme={theme}>视频</VideoName>*/}
                        {/*</VideoContainer>*/}

                    </>
                )
            }

            {theme === Theme.day &&
                <IconContainer size={24} onClick={onThemeClick}><DayIcon /></IconContainer>}
            {theme === Theme.night &&
                <IconContainer size={24} onClick={onThemeClick}><NightIcon/></IconContainer>}
        </HeaderContainer>
    )
}