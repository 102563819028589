import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Theme, themeValue} from "../../common/constants";

export interface MainFilterState{
    theme: Theme
    searchValue: string
    tagIdList: string
    videoPageNum: number
    articlePageNum: number
}

const initialState: MainFilterState = {
    theme: localStorage.getItem(themeValue) as Theme || Theme.day,
    searchValue: '',
    tagIdList: '',
    videoPageNum: 1,
    articlePageNum: 1
}

export const mainFilterSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        setTheme: (state, action: PayloadAction<Theme>) =>{
            state.theme = action.payload
            localStorage.setItem(themeValue, state.theme)
        },
        setSearchValue: (state, action: PayloadAction<string>)=>{
            state.searchValue = action.payload
        },
        setTagIdList: (state, action: PayloadAction<string>)=>{
            state.tagIdList = action.payload
        },
        setVideoPageNum: (state, action: PayloadAction<number>)=>{
            state.videoPageNum = action.payload
        },
        setArticlePageNum: (state, action: PayloadAction<number>)=>{
            state.articlePageNum = action.payload
        },
    }
})
export const { setTheme, setSearchValue, setTagIdList, setVideoPageNum, setArticlePageNum} = mainFilterSlice.actions
export default mainFilterSlice.reducer