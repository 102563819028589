import React, {useEffect} from "react";
import styled, {css, keyframes} from "styled-components";
import {
    ArticleColor, CardBorder, CardGap, CardLenObj, LargeDeviceWidth,
    MediaType,
    MediumDeviceWidth,
    SmallDeviceWidth,
    Theme,
    VideoColor
} from "../../../common/constants";
import {RProp} from "../../../common/interfaces";
import useWindowSize from "../../../hooks/useWindowSize";
import {calculateCardLen, getCardContainerWidth} from "../../../common/utils";


export const  cardBgCss = (mediaType: MediaType, theme: Theme)=>{
    const borderColor = mediaType === MediaType.video? VideoColor.borderColor: ArticleColor.borderColor
    let color1, color2;

    if (theme === Theme.day) {
        [color1, color2] = mediaType === MediaType.video ? [VideoColor.cardDayGradient.color1, VideoColor.cardDayGradient.color2] : [ArticleColor.cardDayGradient.color1, ArticleColor.cardDayGradient.color2];
    } else {
        [color1, color2] = mediaType === MediaType.video ? [VideoColor.cardNightGradient.color1, VideoColor.cardNightGradient.color2] : [ArticleColor.cardNightGradient.color1, ArticleColor.cardNightGradient.color2];
    }

    return css`
      border: ${CardBorder}px dotted ${borderColor};
      background-image: linear-gradient(315deg, ${color1} 0%, ${color2} 74%);
      &:hover{
        border: ${CardBorder}px solid ${borderColor};
      }
    `
}

export const CardContainer = styled.div<{isSmallDevice: boolean,isflipped: boolean, mediatype: MediaType, theme: Theme, onClick: Function, cardLen: number}>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: ${p=> p.cardLen}px;
  height: ${p=>p.isSmallDevice? 220: 275}px;
  border-radius: 24px;
  justify-content: space-between;
  ${p=>cardBgCss(p.mediatype, p.theme)};
  animation: ${({ isflipped }) => (isflipped ? `flipOutY 0.5s` : 'none')};
`

const ListContainer = styled.div<{cardLen: number, cardNum: number}>`
  width: ${p=> getCardContainerWidth(p.cardLen, p.cardNum, CardGap, CardBorder)}px;
  display: flex;
  flex-wrap: wrap;
  //align-items: center;
  gap: ${CardGap}px;
  //row-gap: ${CardGap+5}px;
`
const ListBox = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  justify-content: center;
`

export const CardListContainer: React.FC<RProp> = ({children})=>{
    const {width} = useWindowSize()
    const [cardLen, setCardLen] = React.useState(300)
    const [cardNum, setCardNum] = React.useState(3)

    useEffect(() => {
        if(width< SmallDeviceWidth){
            setCardLen(calculateCardLen(width,2))
            setCardNum(2)
        }else if (width< MediumDeviceWidth){
            setCardLen(CardLenObj[MediumDeviceWidth])
            setCardNum(2)
        }else if(width< LargeDeviceWidth){
            setCardNum(2)
            setCardLen(CardLenObj[LargeDeviceWidth])
        }else{
            setCardNum(3)
            setCardLen(CardLenObj[LargeDeviceWidth])
        }
    }, [width]);

    return (
        <ListBox>
            <ListContainer cardLen={cardLen} cardNum={cardNum}>{children}</ListContainer>
        </ListBox>
    )
}

export const PageContainer = styled.div<{theme: Theme}>`
  display: flex;
  background: ${p=> p.theme === Theme.night? 'black': 'white'};
  border-radius: 24px;
  padding: 5px;
  margin-bottom: 20px;
  width: fit-content;
`