import React from "react";
import {FooterContainer, Line} from "./css";
import dayjs from 'dayjs';
import useAllStates from "../../hooks/useAllStates";
import {MediumDeviceWidth, Theme} from "../../common/constants";
import useWindowSize from "../../hooks/useWindowSize";

export default function Footer(){
    const {theme} = useAllStates()
    const {width} = useWindowSize()
    const lindWidth = width< MediumDeviceWidth? 50: 200
    return(
        <FooterContainer theme={theme}>
            <Line theme={theme} lindWidth={lindWidth}/>
            Nova Tech © {dayjs().year()} All Rights Reserved
            <Line theme={theme} lindWidth={lindWidth}/>
        </FooterContainer>
    )
}