import {useEffect, useRef, useState} from "react";

export default function useScrollButton(scrollAmount: number){
    const listContainerRef = useRef<HTMLDivElement>(null);
    const [showRightArrow, setShowRightArrow] = useState(true);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const scrollRecommendList = (direction: 'left' | 'right') => {
        const listContainer = listContainerRef.current;
        if (!listContainer) return;

        const scrollAmount = 180; // 调整滚动量
        if (direction === 'left') {
            listContainer.scrollBy({left: -scrollAmount, behavior: 'smooth'});
        } else if (direction === 'right') {
            listContainer.scrollBy({left: scrollAmount, behavior: 'smooth'});
        }
        checkScrollPosition();
    };
    const checkScrollPosition = () => {
        const listContainer = listContainerRef.current;
        if (!listContainer) return;

        setTimeout(() => {
            const {scrollLeft, scrollWidth, clientWidth} = listContainer;
            setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 2); // -2 是为了防止因为小数点问题导致的误判
            setShowLeftArrow(scrollLeft > 2);
        }, 100); // 100ms 的延迟应该足够滚动完成
    };

    useEffect(() => {
        checkScrollPosition();
        listContainerRef.current?.addEventListener('scroll', checkScrollPosition);
        return () => {
            listContainerRef.current?.removeEventListener('scroll', checkScrollPosition);
        };
    }, []);

    return {listContainerRef, showLeftArrow, showRightArrow, scrollRecommendList}
}